import React from 'react'

import Header from '../components/Header';
import ContentMain from '../components/ContentMain';
import OrderForm from '../components/OrderForm';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <>
      <Header />
      <ContentMain />
      <OrderForm />
      <Footer />
    </>
  )
}

export default Home
