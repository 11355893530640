export function ReactFbq() {
  
  if(!window.fbq) {
      
    let n = window.fbq = function() {
      n.callMethod ? n.callMethod.apply(n, arguments):
      n.queue.push(arguments);
    };

    if(!window._fbq)
      window._fbq = n;
    
    n.push = n;
    n.loaded = true;
    n.version = '2.0';
    n.queue = [];

    let t = document.createElement('script');
    t.async = true;
    t.src = 'https://connect.facebook.net/en_US/fbevents.js';
    
    let s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(t, s);

  }

  return window.fbq.apply(null, arguments);

}

export function ReactTtq(id) {

  let t = document.createElement('script');
  t.type = 'text/javascript';
  t.async = true;
  t.src = 'https://analytics.tiktok.com/i18n/pixel/sdk.js?sdkid='+ id;
  
  let s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(t, s);

  return;

}
