import React from 'react'

const ButtonPrimary = (props) => {
  return (
    <>
      <a href="#order" className={"bg-red-700 text-white px-6 font-black "+ (props.full ? 'block w-full py-5 text-center text-v3xl stage:text-3xl text-shadow-b1' : 'py-1 text-vbase stage:text-base rounded-md')}>{props.children}</a>
    </>
  )
}

export default ButtonPrimary
