import React, { useEffect } from 'react'
import ButtonPrimary from '../snippets/ButtonPrimary'

import { ReactFbq, ReactTtq } from '../Helpers'

const Header = () => {
  
  useEffect(() => {

    let hn = window.location.hostname;
    let sd = (window.siteData && window.siteData[hn]) || {};

    if(sd.fb_pixel_id) {
      sd.fb_pixel_id.split(',').forEach(id => {
        ReactFbq('init', id.replace(/[^0-9]/g, ''));
      });
      ReactFbq('track', 'PageView');
    }

    if(sd.tt_pixel_id) {
      sd.tt_pixel_id.split(',').forEach(id => {
        ReactTtq(id.replace(/[^0-9A-Z]/g, ''));
      });
    }
    
  }, []);

  return (
    <div className="py-4 px-3 bg-black text-white flex items-center justify-between">
      <img src="images/logo.png" alt="SC Nano" className="w-1/2 h-auto" />
      <ButtonPrimary>SİPARİŞ VER</ButtonPrimary>
    </div>
  )
}

export default Header
