import React from 'react'

const ContentSlice = (props) => {
  return (
    <div className="relative bg-gray-600">
      {props.children}
      {props.num &&
        <img src={"images/slices/"+ props.num +".jpg"} alt="SC Nano" className="w-full h-auto select-none" />
      }
    </div>
  )
}

export default ContentSlice
