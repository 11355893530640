import React from 'react'

const ContentGif = (props) => {
  return (
    <div className="relative bg-gray-600">
      <div className="absolute left-5 bottom-5 w-1/2 z-10">
        <img src="images/logo-white.png" alt="SC Nano Araç Çizik Giderici" className="w-full h-auto select-none" />
      </div>
      <video className="w-full" muted={true} autoPlay={true} loop={true} playsInline={true} poster={"images/gifs/"+ props.num +".jpg"}>
        <source src={"images/gifs/"+ props.num +".mp4"} type="video/mp4" />
        <source src={"images/gifs/"+ props.num +".webm"} type="video/webm" />
      </video>
    </div>
  )
}

export default ContentGif
