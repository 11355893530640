import React, { useEffect } from 'react'

import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { Link } from 'react-router-dom';

import { ReactFbq, ReactTtq } from '../Helpers'

const ContentSuccess = (props) => {

  useEffect(() => {

    let hn = window.location.hostname;
    let sd = (window.siteData && window.siteData[hn]) || {};

    if(sd.fb_pixel_id) {
      sd.fb_pixel_id.split(',').forEach(id => {
        ReactFbq('init', id.replace(/[^0-9]/g, ''));
      });
      ReactFbq('track', 'PageView');
      ReactFbq('track', 'Purchase');
    }

    if(sd.tt_pixel_id) {
      sd.tt_pixel_id.split(',').forEach(id => {
        ReactTtq(id.replace(/[^0-9A-Z]/g, ''));
      });
    }
    
  }, []);
  
  return (
    <div className="px-8 py-12 text-center">
      <div className="text-center">
        <IoMdCheckmarkCircleOutline size="96" className="mx-auto mb-4 text-green-600" />
      </div>
      <div className="font-bold text-lg mb-6">
        Siparişiniz Başarıyla Alındı!
      </div>
      <div className="mb-6">
        Sipariş Numaranız:<br /> <div className="bg-indigo-600 text-center inline-block text-white font-bold px-6 py-1 rounded mt-2">{props.id}</div>
      </div>
      <div className="mb-8">
        Siparişiniz 2-3 gün içinde size ulaşacaktır. Bu esnada siparişinizin durumunu öğrenmek için gerekirse temsilcilerimizle yapacağınız görüşmede lütfen üstteki sipariş numaranızı belirtiniz.
      </div>
      <hr className="mb-8" />
      <div className="italic mb-8">
        Bizi tercih ettiğiniz için teşekkür ederiz.
      </div>
      <Link to="/" className="text-blue-600 underline">SC Nano Sitesine Dön</Link>
    </div>
  )
}

export default ContentSuccess
