import './App.css';

import Home from './pages/Home';
import Success from './pages/Success';

import { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
} from "react-router-dom";

function App() {
  return (
    <div className="max-w-lg mx-auto shadow-lg min-h-screen bg-white">
      <Router>
        <ScrollToTop />
        <Switch>
          <Route component={Success} path="/success" />
          <Route component={Home} path="/" />
        </Switch>
      </Router>
    </div>
  );
}

function ScrollToTop() {
  
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action !== 'POP') {
        window.scrollTo(0, 0);
      }
    })
    return () => unlisten()
  }, [history]);
  
  return (null);

}

export default App;