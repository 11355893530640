import React from 'react';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import PhoneInput from './PhoneInput';

const OrderForm = () => {

  let locSrc = useLocation().search;
  
  const defaultValues = {
    customer_name: '',
    customer_phone: '',
    customer_address: '',
    payment_method: 'cod',
    product_id: 2,
    product_amount: 3,
    site: window.location.hostname,
    referer: new URLSearchParams(locSrc).get('r') || new URLSearchParams(locSrc).get('ref') || '--'
  };
  
  const btnSubmit = document.getElementById('btnSubmit');

  const [values, setValues] = useState(defaultValues);

  const history = useHistory();

  const onValueChange = name => {
    return (e) => {
      setValues(oldValues => ({...oldValues, [name]: e.target.value}));
    }
  }

  const orderFormAlert = () => {

    let ofTop = document.getElementById('order').offsetTop;
    let ofAlert = document.getElementById('orderFormAlert');

    window.scrollTo(0, ofTop);

    ofAlert.classList.remove('hidden');

  };

  const onSubmit = async (e) => {

    e.preventDefault();

    // Validate phone number
    if(values.customer_phone.replace(/[^0-9]/g, '').length < 10) {
      orderFormAlert();
      return;
    }

    btnSubmit.disabled = true;

    const res = await fetch('https://lndg.me/api/placeOrder', {
      method: 'POST',
      body: JSON.stringify(values)
    });

    if(res.status === 200) {
      btnSubmit.disabled = false;
      const data = await res.json();
      if(data.status) {
        history.push('/success', {orderId: data.data.id});
      } else {
        orderFormAlert();
      }
    }
    
  }

  return (
    <form id="order" action="" method="post" onSubmit={onSubmit} className="block px-6 py-8">
      <h2 className="text-xl font-bold text-center mb-6 px-4 py-4 bg-gray-700 text-white rounded">SC NANO SİPARİŞ FORMU</h2>
      <div id="orderFormAlert" className="bg-red-700 text-white text-center p-2 mb-6 text-sm font-semibold rounded hidden">
        Lütfen tüm alanları eksiksiz doldurun.
      </div>
      <div className="mb-4">
        <label htmlFor="customerName" className="font-bold block mb-1">Adınız Soyadınız:</label>
        <input onChange={onValueChange('customer_name')} value={values.customer_name} type="text" id="customerName" className="block w-full rounded-md border-gray-300 shadow focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50" required />
      </div>
      <div className="mb-4">
        <label htmlFor="customerPhone" className="font-bold block mb-1">Telefon Numaranız:</label>
        <PhoneInput onChange={onValueChange('customer_phone')} value={values.customer_phone} type="tel" id="customerPhone" className="block w-full rounded-md border-gray-300 shadow focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50" required />
      </div>
      <div className="mb-4">
        <label htmlFor="customerAddress" className="font-bold block mb-1">Teslimat ve Fatura Adresiniz:</label>
        <textarea onChange={onValueChange('customer_address')} value={values.customer_address} id="customerAddress" className="block w-full rounded-md border-gray-300 shadow focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50" required></textarea>
      </div>
      <div className="mb-4">
        <label htmlFor="paymentMethod" className="font-bold block mb-1">Ödeme Yöntemi Seçiniz</label>
        <select onChange={onValueChange('payment_method')} name="payment_method" defaultValue={values.payment_method} id="paymentMethod" className="block w-full rounded-md border-gray-300 shadow focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
          <option key="cod">Kapıda Nakit Ödeme</option>
          <option key="ccod">Kapıda Kredi Kartı Ödeme</option>
        </select>
      </div>
      <div className="mb-4">
        <div className="font-bold block mb-2">Ürün Seçimi Yapınız</div>
        <label className="border border-gray-200 shadow bg-white px-4 py-3 w-full rounded-lg cursor-pointer flex items-center mb-2">
          <input onChange={onValueChange('product_amount')} type="radio" name="product_amount" value="1" className="form-tick me-1 appearance-none h-4 w-4 border border-gray-300 rounded-full checked:bg-blue-600 checked:border-transparent focus:outline-none" defaultChecked={values.product_amount === 1} />
          <div className="ml-2">
            <div className="font-semibold">1 Adet SC Nano Araç Çizik Giderici - <div className="font-bold inline-block">99₺</div></div>
            <div className="text-sm italic text-gray-600">+17₺ kargo ücreti</div>
          </div>
        </label>
        <label className="border border-gray-200 shadow bg-white px-4 py-3 w-full rounded-lg cursor-pointer flex items-center mb-2">
          <input onChange={onValueChange('product_amount')} type="radio" name="product_amount" value="2" className="form-tick me-1 appearance-none h-4 w-4 border border-gray-300 rounded-full checked:bg-blue-600 checked:border-transparent focus:outline-none" defaultChecked={values.product_amount === 2} />
          <div className="ml-2">
            <div className="font-semibold">2 Adet SC Nano Araç Çizik Giderici - <div className="font-bold inline-block">149₺</div></div>
            <div className="text-sm font-semibold text-green-600">ÜCRETSİZ KARGO</div>
          </div>
        </label>
        <label className="border border-gray-200 shadow bg-white px-4 py-3 w-full rounded-lg cursor-pointer flex items-center mb-2">
          <input onChange={onValueChange('product_amount')} type="radio" name="product_amount" value="3" className="form-tick me-1 appearance-none h-4 w-4 border border-gray-300 rounded-full checked:bg-blue-600 checked:border-transparent focus:outline-none" defaultChecked={values.product_amount === 3} />
          <div className="ml-2">
            <div className="font-semibold">3 Adet SC Nano Araç Çizik Giderici - <div className="font-bold inline-block">199₺</div></div>
            <div className="text-sm font-semibold text-green-600">ÜCRETSİZ KARGO</div>
            <div className="text-sm font-semibold text-red-600">SIVI SERAMİK KAPLAMA HEDİYE</div>
          </div>
        </label>
      </div>
      <div className="mb-4 mt-8">
        <input type="hidden" name="referer" value={values.referer} />
        <input type="hidden" name="site" value={values.site} />
        <input type="hidden" name="product_id" value={values.product_id} />
        <button type="submit" id="btnSubmit" className="flex items-center justify-center bg-gradient-to-b from-blue-500 to-blue-600 hover:bg-green-700 text-white text-lg font-black p-4 rounded shadow w-full btn-submit disabled:from-gray-400 disabled:to-gray-400">SİPARİŞİMİ GÖNDER</button>
      </div>
    </form>
  )
}

export default OrderForm
