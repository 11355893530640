import React from 'react';

const Footer = () => {  
  return (
    <div className="bg-gray-800 text-gray-400 px-6 py-8 mt-12">
      <img src="images/logo-white.png" alt="SC Nano" className="w-1/2 mb-4 block" />
      <div className="grid grid-cols-2 grid-rows-1 gap-x-8 py-4">
        <div>
          <h3 className="font-bold text-vbase stage:text-base">Güvenli Ödeme</h3>
          <div className="text-sm">
            Ürün size ulaştığınızda kapıda ister kredi kartı ister nakit olarak ödeyebilirsiniz.
          </div>
        </div>
        <div>
          <h3 className="font-bold text-vbase stage:text-base">Hızlı Teslimat</h3>
          <div className="text-sm">
            Siparişiniz 1-2 günde kapınızda. Gün içinde verilen siparişler aynı gün kargolanır.
          </div>
        </div>
      </div>
      <div className="border-t border-gray-400 mt-6 text-center text-vsm stage:text-sm italic py-4">
        Copyright &copy; 2022 SC Nano - Tüm hakları saklıdır.
      </div>
    </div>
  )
}

export default Footer
