import React from 'react'

import { Redirect } from 'react-router-dom';

import Header from '../components/Header';
import ContentSuccess from '../components/ContentSuccess';
import Footer from '../components/Footer';

const Success = ({ location }) => {

  const { orderId } = (location && location.state) || {};

  if(orderId) {
    window.history.replaceState({}, document.title);
  }

  return (
    <>
      <Header />
      {orderId ? <ContentSuccess id={orderId} /> : <Redirect to="/" />}
      <Footer />
    </>
  )
}

export default Success
