import React from 'react'

import ButtonPrimary from '../snippets/ButtonPrimary';
import ContentSlice from '../snippets/ContentSlice';
import ContentGif from '../snippets/ContentGif';

import { AiOutlineCheck } from 'react-icons/ai';

const ContentMain = () => {
  return (
    <div id="content">
      <ContentSlice num="1">
        <div className="absolute z-10 left-0 top-0 px-6 pt-8 bg-gradient-to-b from-red-100 w-full">
          <div className="font-black text-v4xl stage:text-4xl tracking-tighter">ARACINIZDAKİ ÇİZİKLERİ</div>
          <div className="font-black text-v5xl stage:text-5xl tracking-tighter">KOLAYCA ONARIN!</div>
          <div className="flex items-center mt-1">
            <AiOutlineCheck size="30" className="flex-shrink-0" />
            <div className="ml-2 font-bold text-v2xl stage:text-2xl italic">Onarır, Korur, Çizikleri Giderir.</div>
          </div>
        </div>
      </ContentSlice>

      <ContentSlice num="2">
        <div className="absolute z-10 right-0 bottom-0 pb-6 pl-3 w-2/3">
          <div className="text-white flex items-start">
            <AiOutlineCheck size="26" className="flex-shrink-0" />
            <div className="ml-2 font-bold text-vxl stage:text-xl leading-tight text-shadow-b1">Aracınızın boyası ne renk olursa olsun ister opak ister metalik tüm kaportalara uygulanabilir.</div>
          </div>
        </div>
      </ContentSlice>

      <ContentGif num="1" />

      <ContentSlice>
        <ButtonPrimary full="true">HEMEN SİPARİŞ VER</ButtonPrimary>
      </ContentSlice>

      <ContentGif num="2" />

      <ContentSlice num="3">
        <div className="absolute z-10 left-0 top-0 pt-20 pl-6 w-2/3">
          <div className="text-vxl stage:text-xl underline">SC NANO ARAÇ ÇİZİK GİDERİCİ</div>
          <div className="text-v4xl stage:text-4xl font-bold tracking-tighter text-shadow-w1">ÇİZİKLERE KARŞI</div>
          <div className="text-v5xl stage:text-5xl font-black tracking-tighter text-shadow-w1">MÜKEMMEL SONUÇ!</div>
          <div className="mt-6 italic text-vxl stage:text-xl w-4/5">Temiz bir bez yardımıyla aracınıza uygulayın, kısa sürede çiziklere elveda!</div>
        </div>
      </ContentSlice>

      <ContentSlice num="4">
        <div className="to-gray-100 from-gray-50 bg-gradient-to-b px-4 pt-8 pb-6">
          <div className="flex items-start mb-3">
            <AiOutlineCheck size="24" className="flex-shrink-0 mt-1 text-red-600" />
            <div className="text-vlg stage:text-lg italic pl-2">Direkt kurur, üzerinde tortu bırakmaz. Bu nedenle neredeyse gözükmez.</div>
          </div>
          <div className="flex items-start mb-3">
            <AiOutlineCheck size="24" className="flex-shrink-0 mt-1 text-red-600" />
            <div className="text-vlg stage:text-lg italic pl-2">Zımparalamaya veya kazımaya gerek olmadan onarma özelliği.</div>
          </div>
          <div className="flex items-start mb-3">
            <AiOutlineCheck size="24" className="flex-shrink-0 mt-1 text-red-600" />
            <div className="text-vlg stage:text-lg italic pl-2">Her renge uygulanabilir.</div>
          </div>
          <div className="flex items-start mb-3">
            <AiOutlineCheck size="24" className="flex-shrink-0 mt-1 text-red-600" />
            <div className="text-vlg stage:text-lg italic pl-2">Derin ve büyük çiziklerde de etkilidir.</div>
          </div>
          <div className="flex items-start mb-3">
            <AiOutlineCheck size="24" className="flex-shrink-0 mt-1 text-red-600" />
            <div className="text-vlg stage:text-lg italic pl-2">Ustaya ihtiyaç duymadan kendiniz uygulayabilirsiniz.</div>
          </div>
          <div className="flex items-start mb-3">
            <AiOutlineCheck size="24" className="flex-shrink-0 mt-1 text-red-600" />
            <div className="text-vlg stage:text-lg italic pl-2">Çiziklere karşı harika sonuç: <b>SC NANO Çizik Giderici</b></div>
          </div>
        </div>
      </ContentSlice>

      <ContentGif num="3" />

      <ContentSlice>
        <ButtonPrimary full="true">HEMEN SİPARİŞ VER</ButtonPrimary>
      </ContentSlice>

      <ContentGif num="4" />

      <ContentSlice num="5">
        <div className="absolute z-10 left-0 top-0 pt-8 pl-6">
          <div className="text-vxl stage:text-xl font-semibold underline">SC NANO ARAÇ ÇİZİK GİDERİCİ</div>
          <div className="text-v4xl stage:text-4xl font-bold tracking-tighter">ARACINIZIN BOYASI</div>
          <div className="text-v4xl stage:text-4xl font-bold tracking-tighter text-shadow-w1">NE RENK OLURSA OLSUN</div>
          <div className="text-v5xl stage:text-5xl font-black tracking-tighter text-shadow-w2">MÜKEMMEL ETKİ!</div>
        </div>
      </ContentSlice>

      <ContentSlice num="6" />

      <ContentSlice num="7">
        <div className="absolute z-10 left-0 top-0 pt-12 pl-4 w-1/2 text-white">
          <div className="flex items-start mb-4 pr-2">
            <AiOutlineCheck size="26" className="flex-shrink-0 text-red-600" />
            <div className="text-vlg stage:text-lg pl-2">Çizikleri onarmada kısa sürede etki.</div>
          </div>
          <div className="flex items-start mb-4 pr-2">
            <AiOutlineCheck size="26" className="flex-shrink-0 text-red-600" />
            <div className="text-vlg stage:text-lg pl-2">Diğer yöntemlere göre en uygun maliyet.</div>
          </div>
          <div className="flex items-start mb-4 pr-2">
            <AiOutlineCheck size="26" className="flex-shrink-0 text-red-600" />
            <div className="text-vlg stage:text-lg pl-2">Aracınız ilk hali gibi yeni ve parlak duracak.</div>
          </div>
          <div className="flex items-start mb-4 pr-2">
            <AiOutlineCheck size="26" className="flex-shrink-0 text-red-600" />
            <div className="text-vlg stage:text-lg pl-2">Özel formülü ile emsallerinden çok daha etkili.</div>
          </div>
          <div className="flex items-start mb-4 pr-2">
            <AiOutlineCheck size="26" className="flex-shrink-0 text-red-600" />
            <div className="text-vlg stage:text-lg pl-2">Uygulaması çok basit, usta gerektirmez.</div>
          </div>
          <div className="flex items-start mb-4 pr-2">
            <AiOutlineCheck size="26" className="flex-shrink-0 text-red-600" />
            <div className="text-vlg stage:text-lg pl-2">Çizikleri gidermenin yanı sıra koruyucu etkisi vardır.</div>
          </div>
        </div>
      </ContentSlice>

      <ContentGif num="5" />

      <ContentSlice>
        <ButtonPrimary full="true">HEMEN SİPARİŞ VER</ButtonPrimary>
      </ContentSlice>

      <ContentSlice num="8">
        <div className="absolute z-10 left-0 bottom-0 pb-8 pl-8 text-white">
          <div className="text-vxl stage:text-xl underline mb-2">SC NANO ARAÇ ÇİZİK GİDERİCİ</div>
          <div className="text-v2xl stage:text-2xl font-semibold leading-tight w-4/5">Toksik madde içermez, su geçirmez, kokusuzdur, kalıcıdır ve her renk boya üzerinde etkilidir.</div>
        </div>
      </ContentSlice>

      <ContentSlice num="9">
        <div className="absolute z-10 left-0 top-0 pt-8 pl-8 text-white">
          <div className="text-v4xl stage:text-4xl font-black text-shadow-b2">Derin ve büyük<br /> çizikleri de ONARIR!</div>
        </div>
      </ContentSlice>

      <ContentGif num="6" />

      <ContentSlice>
        <ButtonPrimary full="true">HEMEN SİPARİŞ VER</ButtonPrimary>
      </ContentSlice>

      <ContentSlice num="10">
        <div className="absolute z-10 left-0 top-0 w-full pt-6 px-4 text-white text-center">
          <div className="text-vxl stage:text-xl underline">SC NANO ARAÇ ÇİZİK GİDERİCİ</div>
          <div className="text-v4xl stage:text-4xl font-black">KULLANICI RESİMLERİ</div>
        </div>
      </ContentSlice>

      <ContentSlice num="11" />
      <ContentSlice num="12" />
      <ContentSlice num="13" />
      <ContentSlice num="14" />

      <ContentSlice num="15">
        <div className="absolute z-10 left-0 bottom-0 pb-6 px-4 text-white text-center w-full">
          <div className="text-vbase stage:text-base">Aracınızın boyası ne renk olursa olsun, ister opak ister metalik, tüm kaportalarda uygulayabilirsiniz.</div>
        </div>
      </ContentSlice>

      <ContentSlice>
        <ButtonPrimary full="true">HEMEN SİPARİŞ VER</ButtonPrimary>
      </ContentSlice>

      <ContentGif num="7" />

      <ContentSlice num="16">
        <div className="absolute z-10 left-0 top-0 pt-12 pl-6 text-white">
          <div className="text-vlg stage:text-lg font-bold underline">SC NANO ARAÇ ÇİZİK GİDERİCİ</div>
          <div className="text-v4xl stage:text-4xl font-bold mb-4 text-shadow-b1">Nasıl Kullanılır?</div>
          <div className="w-1/2 text-vbase stage:text-base font-semibold mb-4 text-shadow-b1 leading-relaxed">Yapmanız gereken son derece basit, çizilmiş olan yüzeye uygulamak.</div>
          <div className="w-1/2 text-vbase stage:text-base font-semibold mb-4 text-shadow-b1 leading-relaxed">Canınızı sıkan çizikleri SC Nano ile hızlıca onarın.</div>
          <div className="w-1/2 text-vbase stage:text-base font-semibold mb-4 text-shadow-b1 leading-relaxed">SC Nano Çizik Giderici çiziğin derinliğinde özel bir katman oluşturur ve güneş ışınları sayesinde kısa sürede kuruyarak aracınızı mükemmel görüntüsüne kavuşturur.</div>
        </div>
      </ContentSlice>
    </div>
  )
}

export default ContentMain
